<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-btn color="green" outlined text @click="openModal">
          <v-icon left dark>mdi-plus</v-icon>Add Outlet
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined small text :to="assignmentRoute">
          <v-icon left dark>mdi-clipboard-check</v-icon>
          Assignments
        </v-btn>
        <v-btn color="primary" outlined small text @click="goBack">
          <v-icon left dark>mdi-skip-backward</v-icon>Back
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field
              filled
              dense
              readonly
              v-model="routeData.Name"
              label="Route Name"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              dense
              readonly
              v-model="routeData.Code"
              label="Route Code"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              dense
              readonly
              v-model="territory.descript"
              label="Region"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-text-field
            class="ma-2 pa-3"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            flat
            dense
            label="search"
          ></v-text-field>
          <v-col cols="12">
            <!-- datatable -->
            <v-data-table
              dense
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="outlets"
              :search="search"
              item-key="id"
            >
              <template v-slot:item.index="{ index }">
                {{ index + 1 }}
              </template>

              <template v-slot:item.id="{ item }">
                <v-btn @click="removeItem(item.id)" color="white" plain small>
                  <v-icon color="red"> mdi-delete-circle-outline</v-icon>
                </v-btn>
              </template>

              <!-- <template v-slot:item.CardName="{ item }">
            <v-btn
              color="flatButton"
              text
              :to="`/outlet/${item.id}`"
            >
              {{
              item.CardName
              }}
            </v-btn>
          </template>  -->

              <!-- <template v-slot:item.territory="props">
            <v-btn color="flatButton" text :to="`/region/${props.item.territory.id}`">{{
              props.item.territory.descript
            }}</v-btn>
          </template> -->
            </v-data-table>
            <!-- end of datatable -->
          </v-col>
        </v-row>
      </v-card-text>

      <v-dialog v-model="Dialog" persistent width="850px">
        <v-card>
          <v-toolbar color="accent">
            <v-toolbar-title>Select Outlets</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="Dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <editor :initial="dataItem" :activeRoute="activeRoute" @data="save"></editor>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/outlet-editor.vue";
//import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    // ...mapGetters("territory", ["territories"]),
    assignmentRoute() {
      return `/routes-assignment/${this.$route.params.id}`;
    },
  },
  data() {
    return {
      dataItem: {},
      activeRoute: {},
      itemsData: [],
      Dialog: false,
      id: null,
      search: null,
      searchInput: null,
      selectionType: "leaf",
      trueValue: true,
      record: {},
      loading: false,
      outletDialog: false,
      regionDialog: false,
      outlets: [],
      territory: {},
      routeData: {},
      headers: [
        { text: "No.", value: "index" },
        { text: "Customer Code", value: "bpartner.CardCode" },
        { text: "Customer Name", value: "bpartner.CardName" },
        // { text: "Region", value: "territory.descript" },
        { text: "Address", value: "bpartner.Address" },
        // { text: "Description", value: "description" },
        { text: "Actions", value: "id", sortable: false },
      ],
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getRouteData",
      immediate: true,
    },
  },
  methods: {
    getRouteData(val) {
      this.id = val;
      const self = this;
      // api call
      this.$store
        .dispatch("get", `/route_outlets/${val}`)
        .then((res) => {
          self.routeData = res.ResponseData?.routeMaster;
          self.outlets = res.ResponseData?.routeOutlets;
          self.territory = res.ResponseData?.routeMaster?.territory;
        })
        .catch((err) => {
          console.log(err, "error");
          // this.$refs.snackbar.show(err, "red");
        });
      // end
    },
    save(data) {
      const self = this;
      data.route_id = self.id;
      const url = "/route_outlets";
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res);
          // self.$router.push(`/routes/${self.id}`);
          this.Dialog = false;
          this.getRouteData(this.$route.params.id);
          this.loader = false;
        })
        .catch((err) => {
          console.log(err, "err");
          this.$refs.snackbar.show(err, "red");
        });
    },
    openModal() {
      this.Dialog = true;
    },
    removeItem(id) {
      var userResponse = confirm(
        "Are you sure you want to delete? This action cannot be undone."
      );
      if (userResponse === true) {
        const url = `/route_outlets/${id}`;
        const self = this;
        self.$store
          .dispatch("remove", url)
          .then(() => {
            this.getRouteData(this.$route.params.id);
          })
          .catch((err) => {
            console.log(err, "err");
            this.$refs.snackbar.show(err, "red");
          });
      } else {
        alert("Operation Cancelled.");
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
