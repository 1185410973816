<template>
  <v-container class="ma-4 pa-2">
    <v-row dense>
      <v-col cols="12" class="mt-2">
        <v-autocomplete
          dense
          v-model="bpartners"
          :items="outlets"
          chips
          :search-input.sync="searchInput"
          deletable-chips
          label="Outlets"
          item-text="CardName"
          item-value="id"
          multiple
        ></v-autocomplete>
      </v-col>
      <v-btn class="mr-1" outlined color="blue" text @click="sendData" :loading="loader">
        <v-icon left light>mdi-content-save</v-icon>Save
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    activeRoute: {
      type: Object,
    },
  },
  data() {
    return {
      search: null,
      searchInput: "",
      selectionType: "leaf",
      bpartners: [],
      territories: [],
      trueValue: true,
      record: {},
      loading: false,
      loader: false,
      outletDialog: false,
      regionDialog: false,
      outlets: [],
    };
  },
  watch: {
    searchInput: {
      handler: "getBpMaster",
      immediate: true,
    },
  },
  methods: {
    addOutlet() {
      this.outletDialog = true;
    },
    openRegionModal() {
      this.regionDialog = true;
    },
    sendData() {
      const self = this;
      const data = {
        bpartners: self.bpartners,
      };
      this.loader = true;
      this.$emit("data", data);
    },
    getBpMaster(val) {
      const self = this;
      if (val) {
        this.$store
          .dispatch("get", `/getCustomers?f=${val}`)
          .then((res) => {
            self.outlets = res;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  created() {
    // this.getBpMaster();
  },
};
</script>

<style lang="scss" scoped></style>
